<template>
    <section id="moments" class="py-5">
        <the-banner-small>Bedelingen</the-banner-small>
        <div class="container my-3">
            <div class="row">
                <div class="col-12 mb-3">
                    <p>
                        De voedsel bedeling loopt van 16.30u tot 18.00u in ons lokaal te Berlare. Tijdens deze bedeling is ook de
                        afdeling kledij is open.
                        <br /><br />
                        <i>Alle bedelingen zijn onderhevig aan de geldende Corona maatregelen</i>
                    </p>
                    <p>
                        <span class="bold">Agenda voor de volgen bedelingen:</span>
                        <ul class="pt-3">
                            <li v-for="moment in moments" :key=moment.id>{{ moment.date }}</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TheBannerSmall from '../components/layout/TheBannerSmall'

export default {
    data() {
        return {
            isLoading: false
        }
    },
    components: {
        TheBannerSmall
    },
    created () {
        this.loadMoments()
    },
    computed: {
        moments () {
            return this.$store.getters['moments/moments']
        }
    },
    methods: {
        async loadMoments () {
            this.isLoading = true
            try {
                await this.$store.dispatch('moments/loadMoments')
            } catch (error) {
                this.error = error.message || 'Something went wrong'
            }
            this.isLoading = false
        }
    }
}
</script>

<style scoped>
.bold {
    font-weight: bold;
}
</style>
